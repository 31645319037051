<template>
  <section>
    <div class="w-full bg-white p-4 rounded-lg mt-4">
      <div class="flex justify-between">
        <div class="w-full">
          <Button @click="$router.push({name: 'pharmasan.ventas.facturacion.facturacion-masiva.evento'})" class="bg-white border-0 text-blue-800 font-bold bg-gray-300" icon="pi pi-chevron-left" label="Atras" />
        </div>
        <div class="flex items-center gap-2 mt-2">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#1C3FAA" d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" />
          </svg>
          <p class="text-lg">Historial</p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 lg:gap-4 mt-4">
        <InputText type="text" style="height: fit-content" placeholder="Buscar ID" v-model="filtros.id" />
        <Dropdown
          v-model="filtros.modalidad"
          :options="modalidades"
          optionLabel="name"
          optionValue="id"
          style="height: fit-content"
          class="border-gray-300 rounded-md items-center"
          placeholder="Modalidad">
        </Dropdown>
        <Dropdown
          v-model="filtros.estado"
          :options="estados"
          optionLabel="name"
          optionValue="id"
          style="height: fit-content"
          class="border-gray-300 rounded-md items-center"
          placeholder="Estado">
        </Dropdown>
        <Dropdown
          v-model="filtros.autor"
          :options="autores"
          :filter="true"
          optionLabel="usuario"
          optionValue="usuario"
          style="height: fit-content"
          class="border-gray-300 rounded-md items-center h-fit"
          placeholder="Autor">
        </Dropdown>
        <div>
          <InputText class="w-full h-fit" type="text" @keyup.enter="guardarOrdenes" placeholder="Ordenes" v-model="ordenSeleccionada" />
          <div class="w-full flex flex-wrap gap-2 mt-2 max-h-20 overflow-y-auto">
            <div
              v-for="(orden, i) in filtros.ordenes"
              :key="i"
              class="max-h-10 overflow-y-auto">
              <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                <button @click="borrarOrdenes(orden)" class="text-blue-800 mr-2">
                  <i style="font-size: 0.6rem" class="pi pi-times"></i>
                </button>
                <p style="inline-size: max-content;">{{orden}}</p>
              </div>
            </div>
          </div>
        </div>
        <input type="text" v-model="filtros.fechaInicio" :max="filtros.fechaFin" placeholder="Fecha desde" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-full p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
        <input type="text" v-model="filtros.fechaFin" :min="filtros.fechaInicio" placeholder="Fecha desde" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-full p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
        <div class="grid grid-cols-2 gap-x-2">
          <Button @click="getHistorial" icon="pi pi-search" label="Buscar" />
          <Button label="Limpiar" @click="limpiarFiltros" class="bg-gray-200 border-0 text-gray-600 hover:bg-red-300" />
        </div>
      </div>
      <DataTable :value="listadoEnvios" responsiveLayout="scroll" class="mt-4">
          <Column headerClass="lg:text-xs w-32" bodyClass="lg:text-xs" field="id" header="ID ENVIO">
            <template  #body="{data}">
              <div class="grupo-id h-8">
                <p class="mt-3 text-blue-600">{{data.id}}</p>
                <button @click="cancelarEnvio(data)" v-if="parseInt(data.TotalGruposProceso) > 0" class="cancelar-envio text-red-600 text-xs underline italic">Cancelar envio</button>
              </div>
            </template>
          </Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="cantidadFacturacionMasiva" header="#OV"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="usuario" header="AUTOR"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="tipoFacturacionMasiva" header="MODALIDAD">
            <template #body="{data}">
              {{data.tipoFacturacionMasiva === 1 ? 'EVENTO' : (data.tipoFacturacionMasiva === 2 ? 'EVENTO/CAPITA' : 'CAPITA')}}
            </template>
          </Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="tipoFacturacionMasiva" header="ESTADO">
            <template #body="{data}">
              <div v-tooltip="`${data.MensajeCancelacion}`" class="flex items-center gap-2" v-if="data.TotalGrupos === data.TotalCanceladas">
                <i class="pi pi-exclamation-circle text-red-600 font-medium text-xs"></i>
                <p class="text-red-400 font-medium text-xs">Envio cancelado</p>
              </div>
              <ProgressBar
                v-else
                v-tooltip.bottom="{value: `Ordenes facturadas: ${data.TotalOrdenesFacturadas} <br/> Pendientes: ${data.TotalOrdenes - data.TotalOrdenesFacturadas}`}"
                class="h-4.5 p-0 rounded-full" :value="parseInt(data.TotalGruposFacturadas / data.TotalGrupos * 100)" >
                <span class="text-xs text-white font-bold">{{ data.TotalGruposFacturadas }} / {{ data.TotalGrupos }}</span>
              </ProgressBar>
            </template>
          </Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="fecha" header="FECHA">
            <template #body="{data}">
              {{dayjs(data.fecha).format('YYYY-MM-DD')}}
            </template>
          </Column>
          <Column
          header="DETALLE"
          headerClass="lg:text-xs"
          bodyClass="lg:text-xs"
          >
            <template #body="{data}">
              <router-link :to="{ name: 'pharmasan.ventas.facturacion.facturacion-masiva.historial-detalle', query: {autor: data.usuario, tipo: data.tipoFacturacionMasiva, fecha: data.fecha, cliente: data.cliente},  params: { id: data.id }}" target="_blank">
                <i class="pi pi-eye text-blue-800"></i>
              </router-link>
            </template>
          </Column>
      </DataTable>
      <div class="lg:flex justify-center text-center mt-2 gap-4 items-center">
        <p class="text-gray-600">
          Mostrando de {{totalItemsCount > 1 ? (offset +1) : offset}} a {{totalItemsCount > limit ? (listadoEnvios.length === limit ? limit + offset : listadoEnvios.length + offset) : totalItemsCount }} de {{totalItemsCount}}
        </p>
        <Paginator v-model:first="offset"
          :rows="limit"
          :totalRecords="totalItemsCount"
          :rowsPerPageOptions="[2,10,20,30, 100, 500]"
          @page="onPage($event)"
        />
      </div>
      <div class="w-full flex justify-end">
        <Button @click="exportarExcel" icon="pi pi-file-excel" label="Exportar" class="p-button-success my-2 lg:my-0" />
      </div>
    </div>
  </section>
</template>
<script>
    import { onMounted, ref, computed } from 'vue'
    import HistorialService from '../../services/historial.service'
    import ExportarExcel from '../../services/exportar_detalle_facturas.service'
    import dayjs from 'dayjs'
    import Swal from 'sweetalert2'
    export default {
      name: 'HistorialFacturacion',
      setup () {
        // Services
        const _HistorialService = ref(new HistorialService())
        // References
        const ordenSeleccionada = ref('')
        const limit = ref(10)
        const offset = ref(0)
        const page = ref(0)
        const totalItemsCount = ref(0)
        const listadoEnvios = ref([])
        const autores = ref([])
        // Data
        const filtros = ref({
          id: '',
          modalidad: '',
          estado: 0,
          autor: '',
          ordenes: [],
          fechaInicio: dayjs().format('YYYY-MM-DD'),
          fechaFin: dayjs().format('YYYY-MM-DD')
        })
        const modalidades = ref([
          {
            name: 'Evento',
            id: 1
          },
          {
            name: 'Capita',
            id: 3
          },
          {
            name: 'Evento/Capita',
            id: 2
          }
        ])
        const estados = ref([
          {
            name: 'Todos',
            id: 4
          },
          {
            name: 'Proceso',
            id: 0
          },
          {
            name: 'Generado',
            id: 1
          },
          {
            name: 'Error',
            id: 2
          },
          {
            name: 'Cancelado',
            id: 3
          }
        ])
        // Computed
        const params = computed(() => {
          return {
            offset: offset.value,
            page: page.value,
            limit: limit.value,
            filters: filtros.value
          }
        })
        // Methods
        const guardarOrdenes = () => {
          for (const orden of ordenSeleccionada.value.split(' ')) {
            filtros.value.ordenes.length ? (
              orden === '' ? filtros.value.ordenes.push() : (
                filtros.value.ordenes.some(a => a === orden) ? filtros.value.ordenes.push() : filtros.value.ordenes.push(orden)
              )
            )
            : filtros.value.ordenes.push(orden)
          }
          ordenSeleccionada.value = ''
        }
        const getHistorial = () => {
          _HistorialService.value.get(params.value).then(({ data }) => {
            totalItemsCount.value = data.count
            listadoEnvios.value = data.rows
          })
        }
        const getAutores = () => {
          _HistorialService.value.autores().then(({ data }) => {
            autores.value = data
          })
        }
        const borrarOrdenes = (orden) => {
          const index = filtros.value.ordenes.findIndex(a => a === orden)
          filtros.value.ordenes.splice(index, 1)
        }
        const limpiarFiltros = () => {
          filtros.value = {
            id: '',
            modalidad: '',
            estado: 4,
            autor: '',
            ordenes: [],
            fechaInicio: dayjs().format('YYYY-MM-DD'),
            fechaFin: dayjs().format('YYYY-MM-DD')
          }
          getHistorial()
        }
        const exportarExcel = () => {
          const object = {
            fechaInicio: filtros.value.fechaInicio,
            fechaFin: filtros.value.fechaFin
          }
          return ExportarExcel(object)
        }
        const onPage = ({ first, page, pageCount, rows }) => {
          limit.value = rows
          getHistorial(params.value)
        }
        const cancelarEnvio = (data) => {
          Swal.fire({
            title: 'Cancelar envio',
            text: `Con esta acción cancelara el envío de ${data.TotalOrdenes} ordenes, esta seguro(a) de continuar?`,
            showDenyButton: true,
            confirmButtonText: 'Sí',
            denyButtonText: 'No'
          }).then((result) => {
            if (result.isConfirmed) {
              _HistorialService.value.cancelarEnvio({ id: data.id }).then(() => {
                Swal.fire(`${data.id} cancelado con exito`, '', 'success').then(() => {
                  getHistorial()
                })
              })
            } else if (result.isDenied) {
              Swal.fire('Cambios no guardados', '', 'info')
            }
          })
        }
        onMounted(() => {
          getHistorial()
          getAutores()
        })
        return {
          filtros,
          listadoEnvios,
          ordenSeleccionada,
          guardarOrdenes,
          borrarOrdenes,
          modalidades,
          estados,
          autores,
          limpiarFiltros,
          limit,
          offset,
          onPage,
          page,
          totalItemsCount,
          dayjs,
          getHistorial,
          exportarExcel,
          cancelarEnvio
        }
      }

    }
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
.cancelar-envio {
  display: none;
}
.grupo-id:hover .cancelar-envio {
  display: flex;
}
</style>
